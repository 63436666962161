<template>
    <div>
        <page-header title="Applications" subtitle="Settings"></page-header>

        <div class="-mx-3">
            <div class="flex w-full flex-wrap">
                <div v-for="app in applications" :key="app.name" class="p-3 flex flex-row flex-wrap w-full lg:w-1/3 xl:w-1/4">
                    <div class="bg-white card w-full">

                        <div class="flex border-b py-12 application-icon-container relative h-50" @click="openSettings(app)" :class="{ 'cursor-pointer settings': canEdit(app) }">

                            <div class="application-icon mx-auto">
                                <fa :icon="['fal', app.design.icon]" v-if="app.design.fontAwesome" class="text-9xl" :class="{'color-brand': app.enabled, 'text-gray-600': !app.enabled}" />
                                <component :is="app.design.icon" v-if="! app.design.fontAwesome" :class="{'color-brand': app.enabled, 'text-gray-600': !app.enabled}"></component>
                            </div>

                            <div class="application-icon-hover mx-auto hidden">
                                <svg-importer icon-name="icons/edit" height="80" width="80" :class="{'stroke-brand': app.enabled, 'opacity-30': !app.enabled}" />
                                <p class="absolute left-0 right-0 mx-auto mt-3 text-center text-gray-800">Edit application settings</p>
                            </div>

                        </div>

                        <div class="flex justify-between w-full px-6 py-4 items-center">
                            <h4 class="w-full">{{ app.name }}</h4>
                            <modal-field v-if="hasPermission" class="pl-4 mr-0 mb-2">
                                <checkbox class="mt-2 mr-0 cur" :disabled="! hasPermission" :name="app.name" @change="toggleActive(app)" v-model="app.enabled"></checkbox>
                            </modal-field>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <modal :show="!! activeApp" @hide="activeApp = null; validationErrors = null" size="2xl">
            <h2 v-if="activeApp" slot="header">Settings for {{ activeApp.name }}</h2>
            <div slot="body" class="pb-6" v-if="activeApp">
                <div class="flex flex-wrap">
                    <modal-field v-for="(input, index) in activeApp.settings" v-if="input.display" :key="input.label" :label="input.label" class="w-1/2 mt-4" :class="{'pr-4': index % 2 === 0, 'pl-4': index % 2 !== 0 }">
                        <input v-if="input.type === 'text'" type="text" :disabled="! activeApp.enabled" v-model="input.data" />
                        <checkbox v-if="input.type === 'checkbox'" :name="input.key" v-model="input.data" :disabled="! activeApp.enabled"></checkbox>
                        <textarea v-if="input.type === 'textarea'" rows="7" :disabled="! activeApp.enabled" v-model="input.data"></textarea>
                        <multi-select
                            v-if="input.type === 'multi-select'"
                            v-model="input.data"
                            no-options="Enter an e-mail address"
                            :options="[]"
                            select-value="value"
                            select-label="key"
                            :dynamic="true"
                            :multiple="true">
                        </multi-select>
                    </modal-field>
                </div>

                <div v-if="validationErrors" class="bg-red-300 mt-4 py-2 px-4">
                    <div v-for="error in validationErrors">
                        {{ error[0] }}
                    </div>
                </div>
            </div>

            <div v-if="activeApp" slot="footer" class="flex w-full items-center">

                <div v-if="activeApp.design.link">
                    <button @click="goToLink(activeApp)" class="btn-primary">{{ activeApp.design.link.label }}</button>
                </div>

                <div class="ml-auto">
                    <button @click="saveSettings()" class="btn-primary select-none">Save</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>

import importedApplications from "~/data/applications.json";
import axios from "axios";
import Checkbox from "~/components/Checkbox";

const MUTUALLY_EXCLUSIVE_APP_KEYS = [
    'proxio_price_leadtime',
    'transport_booking'
];

export default {
    name: 'Applications',
    components: {Checkbox},
    props: {},

    data: () => ({
        applications: {},
        site: {},
        activeApp: null,
        validationErrors: null,
    }),

    created() {
        this.applications = JSON.parse(JSON.stringify(importedApplications));
        this.fetchApplications();

        this.fetchSite(this.siteId);

    },

    computed: {

        hasPermission() {
            return this.user.level === 'super';
        },

        user() {
            return this.$store.getters["auth/user"];
        },

        activeSite() {
            return this.$store.getters["auth/site"]
        },

        siteId() {
            const param = this.$route.params.id;
            return param ? param : this.activeSite.id;
        },

        sites() {
            return this.$store.getters["auth/sites"];
        },

    },

    methods: {

        goToLink(app) {

            if (! app.enabled) {
                this.$snotify.warning(`Please enable ${app.name}`);
                return;
            }

            this.$router.push({name: app.design.link.route, params: {id: this.siteId}});
        },

        canEdit(app) {
            return this.hasSettings(app) && this.hasPermission;
        },

        async setAppDefaultSettings(app) {
            if (!app) {
                return;
            }

            if (app.app_key === "proxio_price_leadtime" || app.app_key === "priceleadtime") {
                const usernameSetting = app.settings.find(setting => setting.key === 'username');
                usernameSetting.data = "vchain_test_api";
                
                return;
            }

            if (app.app_key === "emissions") {
                const usernameSetting = app.settings.find(setting => setting.key === 'username');
                usernameSetting.data = "vchain_root_api";
            }
        },

        openSettings(app) {
            if (!this.canEdit(app)) {
                return;
            }

            this.activeApp = app;
        },

        async fetchApplications() {

            const id = this.siteId;
            const { data } = await axios.get(`${this.$apiUrl.sites.base}/${id}/applications`);
            const applications = data.data;

            applications.forEach(application => {
                let app = this.applications.find(a => a.app_key === application.app_key)

                if (app) {
                    app.enabled = application.enabled;

                    if (application.settings) {
                        const values = application.settings;

                        app.settings.forEach(setting => {
                            setting.data = values[setting.key];
                        });
                    }
                }
            })

            this.applications.forEach(app => {

                data.data.forEach(element => {
                    if (app.app_key === element.app_key && element.settings.length ) {
                        app.enabled = element.enabled;
                        const values = element.settings;

                        app.settings.forEach(setting => {
                            setting.data = values[setting.key];
                        });
                    }
                });
            });
        },

        validationForApplications() {
            this.site.applications = [];

            this.applications.forEach(app => {
                let settings = {};

                app.settings.forEach(setting => {
                    if (setting.data != null && setting.data !== '') {
                        settings[setting.key] = setting.data
                    }
                });

                const applicationObject = {
                    'app_key': app.app_key,
                    'settings': settings,
                    "enabled": app.enabled ? 1 : 0,
                }

                this.site.applications.push(applicationObject);
            });
        },

        async fetchSite(id) {
            try {
                const { data } = await axios.get(`${this.$apiUrl.sites.base}/${id}`);
                this.site = data.data;
            } catch (e) {
                console.log(e, "error");
            }
        },

        hasSettings(app) {
            if (app.settings.length === 0) {
                return false;
            }

            return true;
        },

        async toggleActive(app) {
            // makes sure only 1 of MUTUALLY_EXCLUSIVE_APP_KEYS apps is active
            if (
                app.enabled &&
                MUTUALLY_EXCLUSIVE_APP_KEYS.includes(app.app_key) &&
                MUTUALLY_EXCLUSIVE_APP_KEYS.every(appKey => this.applications.find(app => app.app_key === appKey)?.enabled)
            ) {
                const appToDisable = this.applications.find(application => application.app_key !== app.app_key);
                
                if (appToDisable) {
                    appToDisable.enabled = false
                }
            }

            if (app.enabled && app.settings.length > 0 && this.missingSettings(app)) {
                await this.setAppDefaultSettings(app);
            }

            await this.save()

            if (app.enabled) {
                this.$snotify.success(`${app.name} enabled`);
            } else {
                this.$snotify.warning(`${app.name} disabled`);
            }
        },

        missingSettings(app) {

            let missing = false;

            app.settings.forEach((setting) => {

                if (! setting.data) {
                    missing = true;
                }
            })

            return missing;
        },

        async saveSettings() {
            await this.save(true)
        },

        async save(modalSave = false) {

            this.loading = true;
            let url = `${this.$apiUrl.sites.base}/${this.siteId}/applications`;
            let action = "patch";

            this.validationForApplications();
            this.validationErrors = null;

            try {
                await axios[action](url, this.site);
                
                // update store sites value
                const { data: { data } } = await axios.get(`${this.$apiUrl.sites.base}/${this.siteId}`);

                this.$store.dispatch('auth/changeSite', [...this.sites].map(site => site.id === data.id ? data : site));
                
                this.loading = false;

                if (modalSave) {
                    this.$snotify.success(`Settings saved`);
                    this.activeApp = null;
                }
            } catch (error) {

                this.loading = false;

                if (error.response.status === 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .settings.application-icon-container:hover {
        .application-icon {
            display: none;
        }

        .application-icon-hover {
            display: block;
        }
    }

    .settings.application-icon-container {
        .application-icon {

            display: block;
        }

        .application-icon-hover {
            display: none;
        }
    }


</style>
